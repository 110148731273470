import React from "react";

const Modal = ({ closeModal, messageModal }) => {
  return (
    <div className="containerModal">
      <article className="modal">
        <p className="messageModal">{messageModal}</p>
        <button className="btnModal" onClick={closeModal}>
          OK
        </button>
      </article>
    </div>
  );
};

export default Modal;
