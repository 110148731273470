import React from "react";
import Burger from "./Subcomponents/Burger";
import { Link } from "react-router-dom";
import { linkNavpage } from "../constant";

const Project = () => {
  const projects = [
    {
      title: "Freshly ",
      description: "HTML, CSS, JS",
      image: process.env.PUBLIC_URL + "/img/project/logo.png",
      link: "https://freshly-restaurant.patrickgraceful.com",
      className: "projectFreshly",
    },

    {
      title: "ElectroDrive",
      description: "CSS, SASS, REACT, REDUX, NODE.Js, MariaDB",
      image: process.env.PUBLIC_URL + "/img/project/logoElectroDrive.png",
      link: "https://electro-drive.patrickgraceful.com",
      className: "projectElectroDrive",
    },
    {
      title: "MonPain",
      description:
        "CSS, REACT, Leaflet (création de cartes, intégration de données)",
      image: process.env.PUBLIC_URL + "/img/project/carte.monPain.webp",
      link: "https://mon-pain.patrickgraceful.com",
      className: "projectMonPain",
    },
  ];

  return (
    <>
      <div className="myProjects container">
        <div className="containerFixed">
          <Link to={linkNavpage}>
            <Burger />
          </Link>
        </div>
        <section className="containerSection">
          <div className="oh-all-title titleStyle">
            <h2 className=" titlePages oh__inner-all-title">MES PROJETS</h2>
          </div>
          <div className="projects oh">
            {projects.map((project, index) => (
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`project oh__inner-all ${project.className}`}
                key={index}
              >
                <div className="oh">
                  <img
                    src={project.image}
                    alt={project.title}
                    className=" oh__inner-all"
                  />
                </div>
                <div className="projectDetails">
                  <div className="oh">
                    <h2 className="oh__inner-all">{project.title}</h2>
                  </div>
                  <div className="oh">
                    <p className="oh__inner-all">{project.description}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Project;
