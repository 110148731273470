// import React from "react";
// import { NavLink } from "react-router-dom";
// import {
//   linkBiography,
//   linkContact,
//   linkExperience,
//   linkHome,
//   linkProject,
// } from "../../constant";

// const NavBar = () => {
//   return (
//     <div>
//       <nav>
//         <ul className="navBar">
//           <li>
//             <NavLink to={linkHome}>Accueil</NavLink>
//           </li>
//           <li>
//             <NavLink to={linkProject}>Projets</NavLink>
//           </li>
//           <li>
//             <NavLink to={linkExperience}>Expériences/cv</NavLink>
//           </li>
//           <li>
//             <NavLink to={linkBiography}>Biographie</NavLink>
//           </li>
//           <li>
//             <NavLink to={linkContact}>Contact</NavLink>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default NavBar;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  linkBiography,
  linkContact,
  linkExperience,
  linkHome,
  linkProject,
} from "../../constant";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    setIsMenuOpen(true);
  }, []);
  return (
    <div>
      <nav>
        <ul className={`menu ${isMenuOpen ? "open-menu" : ""}`}>
          <li className="menu__item oh">
            <NavLink className="menu__item-link  oh__inner" to={linkHome}>
              Accueil
            </NavLink>
          </li>
          <li className="menu__item oh">
            <NavLink className="menu__item-link oh__inner" to={linkProject}>
              Projets
            </NavLink>
          </li>
          <li className="menu__item oh">
            <NavLink className="menu__item-link oh__inner" to={linkExperience}>
              Expériences/cv
            </NavLink>
          </li>
          <li className="menu__item oh">
            <NavLink className="menu__item-link oh__inner" to={linkBiography}>
              Biographie
            </NavLink>
          </li>
          <li className="menu__item oh">
            <NavLink className="menu__item-link oh__inner" to={linkContact}>
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;

// import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
// import {
//   linkBiography,
//   linkContact,
//   linkExperience,
//   linkHome,
//   linkProject,
// } from "../../constant";

// const NavBar = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   useEffect(() => {
//     setIsMenuOpen(true);
//   }, []);
//   return (
//     <div>
//       <nav>
//         <ul className={`menu ${isMenuOpen ? "open-menu" : ""}`}>
//           <li className="menu__item">
//             <NavLink className="menu__item-link" to={linkHome}>
//               Accueil
//             </NavLink>
//           </li>
//           <li className="menu__item">
//             <NavLink className="menu__item-link" to={linkProject}>
//               Projets
//             </NavLink>
//           </li>
//           <li className="menu__item">
//             <NavLink className="menu__item-link" to={linkExperience}>
//               Expériences/cv
//             </NavLink>
//           </li>
//           <li className="menu__item">
//             <NavLink className="menu__item-link" to={linkBiography}>
//               Biographie
//             </NavLink>
//           </li>
//           <li className="menu__item">
//             <NavLink className="menu__item-link" to={linkContact}>
//               Contact
//             </NavLink>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default NavBar;
