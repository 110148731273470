import React from "react";
import Burger from "./Subcomponents/Burger";
import NavBar from "./Subcomponents/NavBar";
import { Link } from "react-router-dom";
import { linkHome } from "../constant";

const NavPage = () => {
  return (
    <section className="containerNavpage">
      <div className="containerFixed">
        <Link to={linkHome}>
          <Burger />
        </Link>
      </div>
      <div className="navPageBavBar">
        <NavBar />
      </div>
    </section>
  );
};

export default NavPage;
