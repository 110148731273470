import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  contactMail,
  failedSend,
  linkNavpage,
  linkedin,
  messageSend,
  successfullyMessage,
  url,
} from "../constant.js";
import Modal from "./Subcomponents/Modal";
import Burger from "./Subcomponents/Burger";
import { Link } from "react-router-dom";

const Contact = () => {
  const [messageModal, setMessageModal] = useState("message envoiyer");
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [object, setObject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    // Envoyer les données du formulaire au backend
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ name, email, object, message }),
      mode: "cors",
    })
      .then((response) => response.text())
      .then((data) => {
        setIsSubmitting(false);
        setShowModal(true);
        if (data === successfullyMessage) {
          setMessageModal(messageSend);
        } else {
          setMessageModal(failedSend);
        }
        setName("");
        setEmail("");
        setObject("");
        setMessage("");
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
        setMessageModal(failedSend);
        // Traitez l'erreur ici et affichez un message d'erreur à l'utilisateur.
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <Modal closeModal={closeModal} messageModal={messageModal} />
      )}
      <div className="container div-form-contact ">
        <div className="containerFixed">
          <Link to={linkNavpage}>
            <Burger />
          </Link>
        </div>
        <section
          className={`containerSection containerContact ${
            showModal ? "formOpacity" : ""
          }`}
        >
          <form className="formContainer" onSubmit={handleSubmit}>
            <div className="oh-all-title titleStyle">
              <h2 className=" titlePages oh__inner-all-title">CONTACTEZ-MOI</h2>
            </div>
            <div className="form-contact">
              <address>
                <p className="oh">
                  <a
                    href={`mailto:${contactMail}`}
                    className="flex jc-center ai-center oh__inner-all"
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                    naisso@patrickgraceful.com
                  </a>
                </p>
                <p className="oh">
                  <a
                    href={linkedin}
                    className="flex jc-center ai-center oh__inner-all"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="icond faLinkedin"
                    />
                  </a>
                </p>
                <div className="oh city-bordeaux">
                  <p className="contactCity flex jc-center ai-center oh__inner-all">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="icon oh__inner-all"
                    />
                    Bordeaux
                  </p>
                </div>
              </address>
              <div className="group">
                <article>
                  <div className="form-group">
                    <div className="oh">
                      <label htmlFor="name" className="oh__inner-all">
                        Nom
                      </label>
                    </div>
                    <div className="oh">
                      <input
                        type="text"
                        id="name"
                        // placeholder="Patrick"
                        maxLength=""
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                        className="oh__inner-all"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="oh">
                      <label htmlFor="email" className="oh__inner-all">
                        Email
                      </label>
                    </div>
                    <div className="oh">
                      <input
                        type="email"
                        id="email"
                        // placeholder="exemple@gmail.com"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        className="oh__inner-all"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="oh">
                      <label htmlFor="object" className="oh__inner-all">
                        Objet
                      </label>
                    </div>
                    <div className="oh">
                      <input
                        type="text"
                        id="object"
                        // placeholder="objet"
                        value={object}
                        required
                        onChange={(e) => setObject(e.target.value)}
                        className="oh__inner-all"
                      />
                    </div>
                  </div>
                  <div className="textarea-message form-group">
                    <div className="oh">
                      <label htmlFor="message" className="oh__inner-all">
                        Message
                      </label>
                    </div>
                    <div className="oh">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        value={message}
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        className="oh__inner-all"
                      ></textarea>
                    </div>
                  </div>
                  <div className="input-submit form-group oh">
                    <input
                      type="submit"
                      value="Valider"
                      className="oh__inner-all"
                      disabled={isSubmitting}
                    />
                  </div>
                </article>
              </div>
            </div>
          </form>
        </section>
        <div className="colorBackConctact"></div>
      </div>
    </>
  );
};

export default Contact;
