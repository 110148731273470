export const url = "https://patrickgraceful.com/send-email";

export const CODE_API_KEY = "4282215bdcb64f958c6b955247cbde47";
export const contactMail = "naisso@patrickgraceful.com";

export const successfullyMessage = "Successfully sent";
export const failedSend = "échec de l'envoi";
export const messageSend = "Message envoyé";
export const linkNavpage = "/navigate.link";
export const linkHome = "/";
export const linkProject = "/projects";
export const linkExperience = "/experiences";
export const linkBiography = "/biography";
export const linkContact = "/contact";
export const linkedin = "https://www.linkedin.com/in/patrick-naisso/";
