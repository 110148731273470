import React from "react";
import { Link } from "react-router-dom";
import Burger from "./Subcomponents/Burger";
import { linkNavpage } from "../constant";

const Biography = () => {
  return (
    <div className="containerBiography">
      <header className="headerBiography">
        <div className="burgerBiography">
          <Link to={linkNavpage}>
            <Burger />
          </Link>
        </div>
        <article>
          <div className="oh">
            <h2 className="oh__inner-biography">Patrick NAÏSSO</h2>
          </div>
          <div className="oh">
            <p className="oh__inner-biography">
              Concepteur développeur d'applications web en deuxième année, à la
              recherche d'une alternance
            </p>
          </div>
        </article>
      </header>
      <section id="about" className="containerSection">
        <div className="oh">
          <h3 className="oh__inner-biography">À propos de moi</h3>
        </div>
        <article className="content-about">
          <div className="oh">
            <p className="oh__inner-biography">
              Je suis un ancien militaire avec plus de cinq ans d'expérience
              dans la logistique. Mon intérêt pour les technologies m'a conduit
              vers le développement web, et j'ai récemment obtenu le titre RNCP
              34393 de niveau 5. Actuellement étudiant en deuxième année à la 3w
              Academy en tant que concepteur développeur d'applications, je suis
              à la recherche d'une opportunité d'alternance en entreprise pour
              mettre en pratique mes compétences.
            </p>
          </div>
        </article>
      </section>
      <section id="containerSkills" className="containerSection">
        <div className="oh">
          <h3 className="oh__inner-biography">Compétences</h3>
        </div>
        <article className="skills">
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/html5-icon.png"}
                alt=" logo HTML5"
              />
            </p>
            <p className="oh oh-p">
              <span className="oh__inner-all">Html</span>
            </p>
          </div>
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/css-icon.png"}
                alt=" logo CSS"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Css</span>
            </p>
          </div>
          <div className="skill ">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/sass-icon.png"}
                alt=" logo SASS"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Sass</span>
            </p>
          </div>
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/js-icon.png"}
                alt=" logo javascript"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Javascript</span>
            </p>
          </div>
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/react-icon.png"}
                alt=" logo React"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">React</span>
            </p>
          </div>
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/redux-icon.png"}
                alt=" logo Redux"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Redux</span>
            </p>
          </div>
          <div className="skill oh">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/node-icon.png"}
                alt=" logo Node.js"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Node.js</span>
            </p>
          </div>
          <div className="skill">
            <p className="oh">
              <img
                className="oh__inner-biography"
                src={process.env.PUBLIC_URL + "/img/icon-tech/mysql-icon.png"}
                alt=" logo Mysql"
              />
            </p>
            <p className="oh">
              <span className="oh__inner-all">Mysql</span>
            </p>
          </div>
        </article>
      </section>
    </div>
  );
};

export default Biography;
