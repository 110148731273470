// import React, { useState } from "react";
// import {
//   VerticalTimeline,
//   VerticalTimelineElement,
// } from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
// import { FaBriefcase, FaGraduationCap, FaFileDownload } from "react-icons/fa";
// import Burger from "./Subcomponents/Burger";
// import { Link } from "react-router-dom";
// import { linkNavpage } from "../constant";

// const Experience = () => {
//   const [showCV, setShowCV] = useState(false);

//   const experiences = [
//     {
//       title: "3W Academy",
//       description:
//         "Certification Développeur en réalisation d'applications web | Titre RNCP 34393 - Niveau 5",
//       date: "2022 - 2023",
//       icon: <FaGraduationCap />,
//       iconStyle: { background: " #FAEDCD", color: "#914669" },
//     },
//     {
//       title: "Castel Blanquefort",
//       description: "Cariste, magasinier",
//       date: "2020 - 2022",
//       icon: <FaBriefcase />,
//       iconStyle: { background: " #FAEDCD", color: "#00927f" },
//     },
//     {
//       title: "Sirems Service",
//       description: "Magasinier, préparateur et livreur de commande",
//       date: "2019 - 2000",
//       icon: <FaBriefcase />,
//       iconStyle: { background: " #FAEDCD", color: "#00927f" },
//     },
//     {
//       title: "Armée de Terre",
//       description: "Militaire",
//       date: "2009 - 2014",
//       icon: <FaBriefcase />,
//       iconStyle: { background: " #FAEDCD", color: "#00927f" },
//     },
//     {
//       title: "Lycée polyvalent Bertène Juminer",
//       description: "Bac pro Comptabilité",
//       date: "2004 - 2006",
//       icon: <FaGraduationCap />,
//       iconStyle: { background: " #FAEDCD", color: "#914669" },
//     },
//   ];

//   const handleCVClick = () => {
//     setShowCV(true);
//   };

//   const handleCloseCV = () => {
//     setShowCV(false);
//   };

//   return (
//     <div className="container containerExperiences">
//       <section className="experience-portfolio">
//         <div className="containerFixed">
//           <Link to={linkNavpage}>
//             <Burger />
//           </Link>
//         </div>
//         <div className="containerSection">
//           <div className="oh-all-title titleStyle">
//             <h2 className=" titlePages oh__inner-all-title">
//               MES EXPERIENCES/CV
//             </h2>
//           </div>
//           <article className="article-vertical oh ">
//             <VerticalTimeline className="texte oh__inner-all">
//               {experiences.map((experience, index) => (
//                 <VerticalTimelineElement
//                   key={index}
//                   className={`vertical-timeline-element--experience element-${index} ${
//                     index % 2 === 0 ? "even-element" : ""
//                   }`}
//                   contentStyle={{ background: "white", color: "#914669" }}
//                   contentArrowStyle={{
//                     borderRight: "7px solid rgba(255, 166, 0, 0.694)",
//                   }}
//                   date={experience.date}
//                   iconStyle={experience.iconStyle}
//                   icon={experience.icon}
//                 >
//                   <h3 className="vertical-timeline-element-title">
//                     {experience.title}
//                   </h3>
//                   <p className="vertical-timeline-element-description">
//                     {experience.description}
//                   </p>
//                 </VerticalTimelineElement>
//               ))}
//             </VerticalTimeline>
//           </article>
//         </div>
//       </section>
//       <div className="containercurriculumVitae containerSection oh">
//         <section className="curriculumVitae oh__inner-all-cv">
//           <h2>Curriculum Vitae</h2>
//           {showCV && (
//             <article className="articleVitae">
//               <embed
//                 src={
//                   process.env.PUBLIC_URL + "other-file/Naisso-patrick-cv.pdf"
//                 }
//                 type="application/pdf"
//                 width="100%"
//                 height="600px"
//               />
//               <button
//                 className="btnShowCloseCv btnCloseCv"
//                 onClick={handleCloseCV}
//               >
//                 Fermer
//               </button>
//             </article>
//           )}
//           <div className="showCv">
//             {!showCV && (
//               <button className="btnShowCloseCv" onClick={handleCVClick}>
//                 Afficher CV
//               </button>
//             )}
//           </div>
//           <a
//             href={process.env.PUBLIC_URL + "other-file/Naisso-patrick-cv.pdf"}
//             download
//           >
//             Télécharger CV <FaFileDownload />
//           </a>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default Experience;

import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaBriefcase, FaGraduationCap, FaFileDownload } from "react-icons/fa";
import Burger from "./Subcomponents/Burger";
import { Link } from "react-router-dom";
import { linkNavpage } from "../constant";

const Experience = () => {
  const [showCV, setShowCV] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const experiences = [
    {
      title: "3W Academy",
      description:
        "Certification Développeur en réalisation d'applications web | Titre RNCP 34393 - Niveau 5",
      date: "2022 - 2023",
      icon: <FaGraduationCap />,
      iconStyle: { background: " #FAEDCD", color: "#914669" },
    },
    {
      title: "Castel Blanquefort",
      description: "Cariste, magasinier",
      date: "2020 - 2022",
      icon: <FaBriefcase />,
      iconStyle: { background: " #FAEDCD", color: "#00927f" },
    },
    {
      title: "Sirems Service",
      description: "Magasinier, préparateur et livreur de commande",
      date: "2019 - 2000",
      icon: <FaBriefcase />,
      iconStyle: { background: " #FAEDCD", color: "#00927f" },
    },
    {
      title: "Armée de Terre",
      description: "Militaire",
      date: "2009 - 2014",
      icon: <FaBriefcase />,
      iconStyle: { background: " #FAEDCD", color: "#00927f" },
    },
    {
      title: "Lycée polyvalent Bertène Juminer",
      description: "Bac pro Comptabilité",
      date: "2004 - 2006",
      icon: <FaGraduationCap />,
      iconStyle: { background: " #FAEDCD", color: "#914669" },
    },
  ];
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleCVClick = () => {
    setShowCV(true);
  };

  const handleCloseCV = () => {
    setShowCV(false);
  };

  return (
    <div className="container containerExperiences">
      <section className="experience-portfolio">
        <div className="containerFixed">
          <Link to={linkNavpage}>
            <Burger />
          </Link>
        </div>
        <div className="containerSection">
          <div className="oh-all-title titleStyle">
            <h2 className=" titlePages oh__inner-all-title">
              MES EXPERIENCES/CV
            </h2>
          </div>
          <article className="article-vertical oh ">
            <VerticalTimeline className="texte oh__inner-all">
              {experiences.map((experience, index) => (
                <VerticalTimelineElement
                  key={index}
                  className={`vertical-timeline-element--experience element-${index} ${
                    index % 2 === 0 ? "even-element" : ""
                  }`}
                  contentStyle={{ background: "white", color: "#914669" }}
                  contentArrowStyle={{
                    borderRight: "7px solid rgba(255, 166, 0, 0.694)",
                  }}
                  date={experience.date}
                  iconStyle={experience.iconStyle}
                  icon={experience.icon}
                >
                  <h3 className="vertical-timeline-element-title">
                    {experience.title}
                  </h3>
                  <p className="vertical-timeline-element-description">
                    {experience.description}
                  </p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </article>
        </div>
      </section>
      <div
        className={`containercurriculumVitae containerSection oh ${
          showCV && windowWidth < 550 ? "containercurriculumVitaeShow" : ""
        }`}
      >
        <section
          className={`curriculumVitae oh__inner-all-cv ${
            showCV && windowWidth < 550 ? "curriculumVitaeShow" : ""
          }`}
        >
          <h2>Curriculum Vitae</h2>
          {showCV ? (
            windowWidth < 550 ? (
              <article className="articleVitae">
                <embed
                  src={
                    process.env.PUBLIC_URL +
                    "other-file/Naisso-patrick549-cv.pdf"
                  }
                  type="application/pdf"
                  width="100%"
                  height="600px"
                />
                <div>
                  <button
                    className="btnShowCloseCv btnCloseCv"
                    onClick={handleCloseCV}
                  >
                    Fermer
                  </button>
                </div>
              </article>
            ) : (
              <article className="articleVitae">
                <embed
                  src={
                    process.env.PUBLIC_URL + "other-file/Naisso-patrick-cv.pdf"
                  }
                  type="application/pdf"
                  width="100%"
                  height="600px"
                />
                <button
                  className="btnShowCloseCv btnCloseCv"
                  onClick={handleCloseCV}
                >
                  Fermer
                </button>
              </article>
            )
          ) : null}

          <div className="showCv">
            {!showCV && (
              <button className="btnShowCloseCv" onClick={handleCVClick}>
                Afficher CV
              </button>
            )}
          </div>
          <a
            href={process.env.PUBLIC_URL + "other-file/Naisso-patrick-cv.pdf"}
            download
          >
            Télécharger CV <FaFileDownload />
          </a>
        </section>
      </div>
    </div>
  );
};

export default Experience;
