import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import NavPage from "./Components/NavPage";
import Contact from "./Components/Contact";
import Modal from "./Components/Subcomponents/Modal";
import Biography from "./Components/Biography";
import Project from "./Components/Project";
import Experience from "./Components/Experience";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/navigate.link" element={<NavPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/biography" element={<Biography />} />
        <Route path="/modal" element={<Modal />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/experiences" element={<Experience />} />
      </Routes>
    </div>
  );
}

export default App;
