import React from "react";

const Burger = () => {
  return (
    <div className="containerBurger">
      <div className="cross">
        <div className="spanCross">
          <span className="burgerSpan firstBurger"></span>
          <span className="burgerSpan secondBurger"></span>
          <span className="burgerSpan lastBurger"></span>
        </div>
      </div>
    </div>
  );
};

export default Burger;

// const Burger = () => {
//   return (
//     <div className="containerBurger">
//       <div className="oh__inner-cross">
//         <div className="oh__inner-burger">
//           <span className="burgerSpan firstBurger"></span>
//           <span className="burgerSpan secondBurger"></span>
//           <span className="burgerSpan lastBurger"></span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Burger;
