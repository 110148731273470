import React, { useEffect, useState } from "react";
import Burger from "./Subcomponents/Burger";
import Menu from "./Subcomponents/Menu";
import { Link } from "react-router-dom";
import { linkNavpage } from "../constant";

const Home = () => {
  const [showSectionHome, setShowSectionHome] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClickImgAllHome = (e) => {
    e.preventDefault();
    setShowSectionHome(true);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <main
      className={`containerHome ${showSectionHome ? "backgroundNone" : ""}`}
      onClick={(e) => handleClickImgAllHome(e)}
    >
      <div
        className={`imgSecondHome ${
          showSectionHome || windowWidth <= 1100 ? "displayNone" : ""
        }`}
      >
        <h1>Developpeur Web</h1>
        <div>
          <Burger />
        </div>
      </div>
      <section
        className={`sectionHome imgFirstHome ${
          showSectionHome || windowWidth <= 1100 ? "imgShowSection" : ""
        }`}
      >
        <div className="flex sp-between">
          <div className="oh">
            <h3 className="titleName oh__inner-home">Patrick NAÏSSO</h3>
          </div>
          <div className="sectionHomeBurger">
            {windowWidth <= 550 ? (
              <Link to={linkNavpage}>
                <Burger />
              </Link>
            ) : (
              <div>
                {showSectionHome || windowWidth <= 1100 ? (
                  <Link to={linkNavpage}>
                    <Menu />
                  </Link>
                ) : (
                  <Menu />
                )}
              </div>
            )}
          </div>
        </div>
        <article className="welcome">
          <div className="oh">
            <h2 className="oh__inner-home">Bienvenue</h2>
          </div>
          <div className="oh">
            <p className="oh__inner-home">
              Bonjour, je suis un Explorateur Développeur.
            </p>
          </div>
        </article>
      </section>
    </main>
  );
};

export default Home;

// import React, { useEffect, useState } from "react";
// import Burger from "./Subcomponents/Burger";
// import Menu from "./Subcomponents/Menu";
// import { Link } from "react-router-dom";
// import { linkNavpage } from "../constant";

// const Home = () => {
//   const [showSectionHome, setShowSectionHome] = useState(false);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const handleClickImgAllHome = (e) => {
//     e.preventDefault();
//     setShowSectionHome(true);
//   };

//   useEffect(() => {
//     const handleWindowResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleWindowResize);

//     return () => {
//       window.removeEventListener("resize", handleWindowResize);
//     };
//   }, []);
//   return (
//     <main
//       className={`containerHome ${showSectionHome ? "backgroundNone" : ""}`}
//       onClick={(e) => handleClickImgAllHome(e)}
//     >
//       <div className={`imgSecondHome ${showSectionHome ? "displayNone" : ""}`}>
//         <h1>Developpeur Web</h1>
//         <div>
//           <Burger />
//         </div>
//       </div>
//       <section
//         className={`sectionHome imgFirstHome ${
//           showSectionHome ? "imgShowSection" : ""
//         }`}
//       >
//         <div className="flex sp-between">
//           <div className="oh">
//             <h3 className="titleName oh__inner-home">Patrick NAÏSSO</h3>
//           </div>
//           <div className="sectionHomeBurger">
//             {windowWidth <= 550 ? (
//               <Link to={linkNavpage}>
//                 <Burger />
//               </Link>
//             ) : (
//               <div>
//                 {showSectionHome || windowWidth <= 1100 ? (
//                   <Link to={linkNavpage}>
//                     <Menu />
//                   </Link>
//                 ) : (
//                   <Menu />
//                 )}
//               </div>
//             )}
//           </div>
//         </div>
//         <article className="welcome">
//           <div className="oh">
//             <h2 className="oh__inner-home">Bienvenue</h2>
//           </div>
//           <div className="oh">
//             <p className="oh__inner-home">
//               Bonjour, je suis un Explorateur Développeur.
//             </p>
//           </div>
//         </article>
//       </section>
//     </main>
//   );
// };

// export default Home;
