import React from "react";

const Menu = () => {
  return (
    <div className="containerMenu">
      <div className="oh">
        <span className="menu-span oh__inner-home"></span>
      </div>
    </div>
  );
};

export default Menu;
